import React, {useContext, useEffect} from "react";
import {Badge} from "@vacasa/react-components-lib";
import {EmailStepContext} from "../../../../context/EmailStepContext";
import {ReservationContext} from "../../../../context/ReservationContext";
import style from "./EmailHeader.module.scss";
import {PillsInput, PillsInputCallbackType} from "../../inputs/pills-input/pills-input";

export const EmailHeader: React.FC<any> = () => {
  const reservationContext = useContext(ReservationContext);
  const {reservationData} = reservationContext;
  const confirmation_code = reservationData?.attributes?.confirmation_code;
  const emailStepContext = useContext(EmailStepContext);
  const {emailStep, setEmailStep} = emailStepContext;
  const emailData = emailStep.email_step.email_data;

  const handleRecipientEmails = (e: PillsInputCallbackType) => {
    setEmailStep({...emailStep}, (emailData.recipients_emails = e.items), (emailData.error = e.error));
  };

  return (
    <div className={style.headerEmail}>
      <div className={style.emailContacts}>
        <span className={style.titleEmails}>To:</span>
        <PillsInput
          pillComponent={Badge}
          pillTextField={"text"}
          pillCallbackDeleteField={"handleClose"}
          initialPillsProps={
            reservationData?.attributes?.guestInformation?.email
              ? [
                  {
                    disabled: true,
                    closeButton: false,
                    isPill: true,
                    variant: "default",
                    outline: false,
                    text: reservationData?.attributes?.guestInformation?.email,
                  },
                ]
              : []
          }
          pillValidProps={{
            disabled: false,
            closeButton: true,
            isPill: true,
            variant: "default",
            outline: false,
          }}
          pillInvalidProps={{
            disabled: false,
            closeButton: true,
            isPill: true,
            variant: "error",
            outline: false,
          }}
          allowDuplicated={false}
          typeValidator={"email"}
          callbackState={handleRecipientEmails}
          inputPlaceholder={"add recipient"}
        />
      </div>
      <p>Subject: Vacasa Reservation Cancellation: {confirmation_code}</p>
    </div>
  );
};
