import React, {useEffect, useState} from "react";
import {ResManService} from "@common/services";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import {FrontendConfiguration} from "@common/configuration";
import {Finances} from "@common/utils";
import {PaymentsItem, PaymentsSummaryItem, RefundDataV2} from "@common/typing";

export const ReservationContext = React.createContext<any>({});

const ReservationProvider = (props) => {
  const resManService = ResManService.getInstance();
  const resInfo = jwtDecode(window.localStorage.getItem(FrontendConfiguration.tokenStorageName)) as any;
  const [reservationData, setReservationData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [unitData, setUnitData] = useState(null);
  const [paymentSummary, setPaymentSummary] = useState<PaymentsSummaryItem>(null);
  const [payments, setPayments] = useState<PaymentsItem>(null);
  const [refundData, setRefundData] = useState<RefundDataV2>(null);
  const [hasFSCAmount, setHasFSCAmount] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (_.isNil(reservationData)) {
          const reservationInfo = await resManService.getReservationById(resInfo?.reservation_uuid);
          setReservationData(reservationInfo);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (_.isNil(unitData) && !_.isNil(reservationData)) {
          const unitId = reservationData?.attributes?.unit_id;
          const unitInformation = unitId ? await resManService.getUnitById(unitId) : {};
          setUnitData(unitInformation);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [unitData, reservationData]);

  useEffect(() => {
    (async () => {
      try {
        if (_.isNil(paymentSummary)) {
          const reservationPaymentSummary = await resManService.getReservationPaymentSummary(resInfo?.legacy_reservation_id);
          setPaymentSummary(reservationPaymentSummary);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [paymentSummary]);

  useEffect(() => {
    (async () => {
      try {
        if (_.isNil(payments)) {
          const reservationPayments = await resManService.getPayments(resInfo?.legacy_reservation_id);
          reservationPayments.data.forEach((data) => {
            _.remove(data.attributes.requests, (req) => req.creditAccount.deleted_at);
          });

          setPayments(reservationPayments);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [payments]);

  useEffect(() => {
    try {
      if (!_.isNil(paymentSummary) && !_.isNil(payments)) {
        const creditsCardsRefund = Finances.getCreditCardsRefundData(payments, paymentSummary);
        const totalRefund = Finances.getTotalRefundAmount(payments);
        const totalPaid = Finances.getTotalPaidAmount(payments);
        const creditCardRefundAmount = Finances.getCreditCardsRefundAmount(payments);
        const fscAmount = Finances.getFSCRefundAmount(payments);
        const fscRefundData = Finances.getFSCRefundData(payments);
        if (fscAmount > 0) setHasFSCAmount(true);
        const checkAmount = Finances.getCheckRefundAmount(payments);
        setRefundData({
          refundType: null,
          currencyCode: paymentSummary?.meta?.amount?.currency || "USD",
          totalRefund: totalRefund,
          totalPaid: totalPaid,
          creditCardsRefundData: creditsCardsRefund,
          creditCardsRefundAmount: creditCardRefundAmount,
          fscRefundAmount: fscAmount,
          fscRefundData: fscRefundData,
          checkRefundAmount: checkAmount,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [payments, paymentSummary]);

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("idToken");
      if (!!token) {
        setAdminData(jwtDecode(token));
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <ReservationContext.Provider
      value={{
        resInfo,
        reservationData,
        adminData,
        unitData,
        paymentSummary,
        payments,
        setReservationData,
        refundData,
        setRefundData,
        hasFSCAmount,
      }}
    >
      {props.children}
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;
