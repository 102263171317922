import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {FrontendConfiguration} from "@common/configuration";
import {ImplicitFlow, JwtValidator, ThemeProvider} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import {App} from "./components/app/App";
import ReservationProvider from "./context/ReservationContext";

const params = new URLSearchParams(window.location.search);

let token: string = params.get("token");
const adminConfig = FrontendConfiguration.adminConfig();

Sentry.init({
  dsn: FrontendConfiguration.getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

// if loaded, we remove the reference to force refreshed cache
sessionStorage.removeItem("cache-force-refreshed")

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      <JwtValidator jwt={token} base64Key={adminConfig.publicKey} storageName={FrontendConfiguration.tokenStorageName}>
        <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()}>
          <ReservationProvider>
            <App />
          </ReservationProvider>
        </ImplicitFlow>
      </JwtValidator>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
