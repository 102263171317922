import React, {useContext, useState} from "react";
import {EmailStepContext} from "../../../../context/EmailStepContext";
import {ReservationContext} from "../../../../context/ReservationContext";
import styles from "./EmailBody.module.scss";
import logo from "../../../../assets/images/vacasa_identity_lockup_horizontal_blue.svg";
import moment from "moment";
import cx from "classnames";
import {FrontendConfiguration} from "@common/configuration";
import {Select} from "../../inputs/select/Select";
import {refundTypeData, refundTypeMessage, refundAmountTypes} from "../../../../data/staticData";
import {RefundType} from "@common/typing";
import {toLocalString} from "../../../../utils";

export const EmailBody: React.FC<any> = () => {
  const emailStepContext = useContext(EmailStepContext);
  const reservationContext = useContext(ReservationContext);
  const {emailStep, setEmailStep} = emailStepContext;
  const {reservationData, unitData, refundData, setRefundData, hasFSCAmount} = reservationContext;

  const emailData = emailStep?.email_step?.email_data;
  const firstNight = reservationData?.attributes?.first_night;
  const lastNight = reservationData?.attributes?.last_night;

  const [selected, setSelected] = useState("DEFAULT");
  const onSelectChange = ({id, text}) => {
    setSelected(id);
    setRefundData({...refundData, ...{refundType: id}});
  };

  const handleFSCAmountChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const fscString = evt.target.value.replace(/\n/gi, " ");
    const fscAmount = Number(fscString !== "" ? fscString : 0);
    setRefundData({
      ...refundData,
      ...{
        fscRefundAmount: fscAmount,
        fscRefundData: fscAmount > 0 ? [{FSC_AMOUNT: fscAmount, TARGET_RES: ""}] : [],
        totalRefund: refundData.creditCardsRefundAmount + fscAmount + refundData.checkRefundAmount,
      },
    });
  };

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmailStep({...emailStep}, (emailData.body_information = evt.target.value.replace(/\n/gi, " ")));
  };

  const filterRefundTypeOptions = (refundTypes) => {
    return refundTypes
      .filter((type) => (refundData?.totalRefund > 0 ? type.id !== RefundType.NO_REFUND : true))
      .filter((type) => (refundData?.totalPaid > 0 ? type.id !== RefundType.NO_FUNDS_COLLECTED : true));
  };

  return (
    <div className={styles.bodyEmail}>
      <div className={styles.emailContent}>
        <img src={logo} alt={"vacasa horizontal logo"} className={styles.logo} />
        <div className={styles.title}>Confirmation of reservation cancellation</div>
        <div className={styles.subtitle}>Your reservation in {unitData?.address?.city} has been successfully cancelled</div>
        <table>
          <tbody className={styles.table}>
            <tr>
              <td className={styles.unitName}>{unitData?.name}</td>
              <td className={cx(styles.reservationCode, styles.tableRightColumn)}>
                Reservation: {reservationData.attributes.confirmation_code ?? reservationData?.attributes?.legacy_reservation_id}
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.dateLabel}>Check in</span>
                <br />
                {moment(firstNight).format("MMM DD, YYYY")}
              </td>
              <td className={styles.tableRightColumn}>
                <span className={styles.dateLabel}>Check out</span>
                <br />
                {moment(lastNight).add(1, "days").format("MMM DD, YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
        <strong>Optional note to Guest:</strong>
        <textarea className={styles.textAreaNote} onChange={handleChange} value={emailStep.body_information} />
        <div className={styles.select}>
          <Select label={"Select refund type"} options={filterRefundTypeOptions(refundTypeData)} onChange={onSelectChange} value={selected} />
        </div>

        <div className={styles.emailTextMessage}>
          {refundTypeMessage[selected] && <p>{refundTypeMessage[selected]}</p>}

          {refundAmountTypes.indexOf(selected) !== -1 && (
            <div>
              <p>Your refund of {toLocalString(refundData?.totalRefund, refundData?.currencyCode)} will be sent as follows:</p>

              <ul>
                {refundData?.creditCardsRefundData?.map((c, index) => (
                  <li key={index}>
                    {toLocalString(c?.CARD_AMOUNT, refundData?.currencyCode)} on your credit card ending in <strong>{c?.CARD_NUMBER}</strong>.
                  </li>
                ))}
                {refundData?.checkRefundAmount > 0 && <li>{toLocalString(refundData?.checkRefundAmount, refundData?.currencyCode)} by check.</li>}
                {hasFSCAmount ? (
                  refundData.fscRefundData?.map((e, index) => (
                    <li key={index}>
                      {toLocalString(e?.FSC_AMOUNT, refundData?.currencyCode)}{" "}
                      {e?.TARGET_RES ? `transfer to your new reservation ${e?.TARGET_RES}.` : "by Future Stay Credit."}
                    </li>
                  ))
                ) : (
                  <li>
                    $<input className={styles.fscInput} placeholder={"FSC Amount"} type={"number"} onChange={handleFSCAmountChange} /> by Future Stay
                    Credit.
                  </li>
                )}
              </ul>
              <div>
                <p>Refund time depends on your payment method and may take up to 10 business days.</p>
                <p>
                  Please note that all transactions are processed in their original booked currency. (<strong>{refundData?.currencyCode}</strong>)
                </p>
              </div>
            </div>
          )}
        </div>

        <br />
        <p>
          Have questions? Please visit our{" "}
          <a target={"_blank"} href={FrontendConfiguration.getSupportAndContactLink()}>
            Support and Contact page.
          </a>
        </p>
      </div>
    </div>
  );
};
