import React, {useState} from "react";

interface IEmailStep {
  email_step: {
    email_data: {
      recipients_emails: Array<string>;
      body_information: string;
      error: boolean;
    };
  };
}

export const EmailStepContext = React.createContext<any>(null);

const EmailStepProvider = ({children}) => {
  const [emailStep, setEmailStep] = useState<IEmailStep>({
    email_step: {
      email_data: {
        recipients_emails: [],
        body_information: "",
        error: false,
      },
    },
  });

  return (
    <EmailStepContext.Provider
      value={{
        emailStep,
        setEmailStep,
      }}
    >
      {children}
    </EmailStepContext.Provider>
  );
};

export default EmailStepProvider;
