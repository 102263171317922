import React, {useContext, useState} from "react";
import {Button} from "../../common-components/inputs/button/Button";
import {EmailContainer} from "../../common-components/email/email-container/EmailContainer";
import styles from "./EmailStep.module.scss";
import {EmailStepContext} from "../../../context/EmailStepContext";
import {ReservationContext} from "../../../context/ReservationContext";
import {Modal} from "@vacasa/react-components-lib";
import {ModalImage} from "../../common-components/modal/modal-image/ModalImage";
import image from "../../../assets/images/early_check_in.png";
import {ResManService} from "@common/services";
import * as Sentry from "@sentry/react";
import {Image} from "@vacasa/react-components-lib";
import {refundAmountTypes} from "../../../data/staticData";
import {CancelNotificationPayloadV2, RefundType} from "@common/typing";
import {FrontendConfiguration} from "@common/configuration";

export const EmailStep: React.FunctionComponent<any> = ({data}) => {
  const reservationContext = useContext(ReservationContext);
  const {resInfo, reservationData, refundData} = reservationContext;
  const emailStepContext = useContext(EmailStepContext);
  const {emailStep} = emailStepContext;
  const emailData = emailStep.email_step.email_data;
  const [hasRegularError, setHasRegularError] = useState<boolean>(false);
  const [hasNoRefundError, setHasNoRefundError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [errorsObject, setErrorsObject] = useState({
    title: "",
    description: "",
  });
  const resManService = ResManService.getInstance();
  const {url} = FrontendConfiguration.adminConfig();

  const handleClick = async () => {
    try {
      if (!refundData.refundType) {
        setHasRegularError(true);
        setErrorsObject({
          title: "No Refund Type specified",
          description: "You must specify a refund type",
        });
      } else if (refundAmountTypes.indexOf(refundData.refundType) !== -1 && refundData.totalRefund === 0) {
        setHasNoRefundError(true);
      } else if (emailData.recipients_emails.length === 0) {
        setHasRegularError(true);
        setErrorsObject({
          title: "Empty recipients",
          description: "You must add recipients to send the email",
        });
      } else if (emailData.error) {
        setHasRegularError(true);
        setErrorsObject({
          title: "Recipient with invalid format",
          description: "Some recipient has an invalid format. You must correct it before sending the email",
        });
      } else {
        setIsLoading(true);
        const payload: CancelNotificationPayloadV2 = {
          to: emailData.recipients_emails.map((r) => ({
            address: r,
            name:
              r === reservationData.attributes?.guestInformation?.address
                ? `${reservationData.attributes?.guestInformation?.first_name} ${reservationData.attributes?.guestInformation?.last_name}`
                : r,
            type: "to",
          })),
          note: emailData.body_information,
          ...refundData,
        };

        await resManService.sendEmailCancellationV2(resInfo.reservation_uuid, payload);

        setIsLoading(false);
        setShowModalSuccess(true);

        setTimeout(() => {
          handleClickAdminRedirect();
        }, 2000);
      }
    } catch (error) {
      setHasRegularError(true);
      setIsLoading(false);
      setErrorsObject({
        title: "An error has occurred",
        description: "Whoops! It was not possible to send the email.",
      });
      error && Sentry.captureException(error);
    }
  };

  const handleClickAdminRedirect = () => {
    window.top.location.href = `${url}/admin/dashboard/reservation/${resInfo.legacy_reservation_id}`;
  };

  const ErrorSendingEmail = () => {
    const {title, description} = errorsObject;
    return (
      <Modal setShowModal={setHasRegularError} showModal={hasRegularError} size="small">
        <div className={styles.modalContent}>
          <ModalImage image={image} />
          <h3 className={styles.titleMessage}>{title}</h3>
          <p className={styles.descriptionMessage}>{description}</p>
        </div>
      </Modal>
    );
  };

  const ErrorNoRefund = () => {
    return (
      <Modal setShowModal={setHasNoRefundError} showModal={hasNoRefundError} size="small">
        <div className={styles.modalContent}>
          <ModalImage image={image} />
          <h3 className={styles.titleMessage}>No Refund Amount</h3>
          <p className={styles.descriptionMessage}>
            This canceled reservation currently has a refund of $0. If there should be a refund to the guest, then go back to the reservation and
            update the refund before sending the cancel email.{" "}
            <a href={`${url}/admin/dashboard/reservation/${resInfo.legacy_reservation_id}`}>Go to Reservation</a>
          </p>
          <p className={styles.descriptionMessage}>If there is no refund, then select one of these options on this cancel email page:</p>
          <ul>
            <li>Not refund eligible</li>
            <li>No payment received</li>
          </ul>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.box}>
          <h2 className={"boxTitle"}>Send confirmation email</h2>
          <p>This reservation is being cancelled. Send the guest a confirmation of their cancellation.</p>

          <EmailContainer data={data} title="Email Preview" />
        </div>

        <div className={styles.btnBox}>
          <Button label={"Send Email"} mode="primary" onClick={handleClick} />
        </div>
      </div>
      <Modal showModal={isLoading} setShowModal={setIsLoading} size={"small"} canExit={false}>
        <div className={styles.sendingMessage}>
          <Image.Spinner style={{display: "flex"}} width={50} />
          <span className={styles.message}>Sending email...</span>
        </div>
      </Modal>
      <Modal showModal={showModalSuccess} setShowModal={setShowModalSuccess} size={"small"}>
        <div className={styles.sendingMessage}>
          <span className={styles.message}>Email sent successfully</span>
        </div>
      </Modal>
      {hasRegularError && <ErrorSendingEmail />}
      {hasNoRefundError && <ErrorNoRefund />}
    </>
  );
};
