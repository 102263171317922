import "../../styles/base.module.scss";

import React, {useContext} from "react";
import {EmailStep} from "./email-step/EmailStep";
import {ReservationContext} from "../../context/ReservationContext";
import {ReservationDetails} from "../common-components/reservation-details/ReservationDetails";
import styles from "../app/App.module.scss";
import cx from "classnames";
import EmailStepProvider from "../../context/EmailStepContext";
import {Image} from "@vacasa/react-components-lib";

export const App: React.FunctionComponent<any> = () => {
  const reservationContext = useContext(ReservationContext);
  const {reservationData, unitData} = reservationContext;

  return (
    <>
      {!reservationData || !unitData ? (
        <Image.Spinner className={styles.center} width={70} />
      ) : (
        <div className={cx("columnFlex", "innerMargin")}>
          <div className={"rowFlex"}>
            <div className={cx(styles.backTitle, "columnFlex")}>
              <h1 className={styles.title}>Cancel Reservation</h1>
            </div>
            <div className={cx(styles.reservationDetails, "rowReverseFlex")}>
              <ReservationDetails />
            </div>
          </div>
          <div>
            <EmailStepProvider>
              <EmailStep />
            </EmailStepProvider>
          </div>
        </div>
      )}
    </>
  );
};
