import React, {useContext} from "react";
import moment from "moment";
import styles from "./ReservationDetails.module.scss";
import {ReservationContext} from "../../../context/ReservationContext";

export const ReservationDetails: React.FunctionComponent<any> = () => {
  const reservationContext = useContext(ReservationContext);
  const {adminData, reservationData} = reservationContext;
  const createdReservationDate = reservationData?.attributes?.created_at;
  const date = moment(createdReservationDate).format("YYYY-MM-DD");
  const hour = moment(createdReservationDate).format("h:mm A");

  return (
    <>
      <div className={styles.detailsItem}>
        <div className={styles.created}>Created</div>
        {createdReservationDate ? (
          <>
            <span className={styles.highlight}>{date}</span>
            <span className={styles.createdHour}>{hour}</span>
          </>
        ) : (
          <span className={styles.highlight}>No Information</span>
        )}
      </div>
      <div className={styles.detailsItem}>
        <div className={styles.reservationId}>Res ID</div>
        <span className={styles.highlight}>{reservationData?.attributes?.legacy_reservation_id}</span>
      </div>
    </>
  );
};
